import React, { useEffect, useRef } from 'react';
import { Wrapper, Video } from './styled';

const videoPath = '/ATELIWARE_03.webm';
const videoPathFallback = '/ATELIWARE_03.mp4';

export const PreFooter = () => {
  const videoRef = useRef();
  useEffect(() => {
    const verifyIfCanPlayVideo = () => {
      const elem = document.createElement('video');
      try {
        return elem
          .canPlayType('video/webm; codecs="vp8, vorbis"')
          .replace(/^no$/, '');
      } catch (e) {
        return false;
      }
    };

    const canPlayWebM = verifyIfCanPlayVideo();
    if (canPlayWebM) {
      videoRef.current.src = videoPath;
    } else {
      videoRef.current.src = videoPathFallback;
    }
  }, []);
  return (
    <Wrapper>
      <Video
        ref={videoRef}
        controls={false}
        autoPlay
        muted
        loop
        playsInline
      />
    </Wrapper>
  );
};
