import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  white-space: normal;
  padding: 129px 0;
  background: #191919;
`;

export const Container = styled.div`
  max-width: var(--content-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.h2`
  color: white;
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -2%;
  text-align: center;
  width: 100%;
`;

export const CtaButton = styled.a`
  font-family: var(--secondary-font);
  cursor: pointer;
  color: #191919;
  background: white;
  height: 46px;
  padding: 0 24px;
  display: block;
  border: none;
  outline: none;
  text-decoration: none;
  line-height: 46px;
`;

export const FormWrapper = styled.div`
  display: block;
  max-width: 100%;
  padding: 0 1rem;

  input,
  textarea,
  .react-tel-input > input {
    background: #191919;
    color: white;

    ::-webkit-input-placeholder {
      color: #939393;
    }
  }

  label {
    color: white;
    max-width: 100%;
    p {
    }
  }

  .react-tel-input {
    .form-control {
      input {
        background: #191919;
      }

      &:focus {
        background: #191919;
        input {
          background: #191919;
        }
      }
    }
  }

  #contact-submit {
    height: 46px !important;
    &:disabled {
      background: #484848;
      border: transparent;
    }
  }
`;
