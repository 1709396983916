import MarkdownPreview from '@uiw/react-markdown-preview';
import styled, { css } from 'styled-components';
import {
  MOBILE_BREAKPOINT,
  WIDESCREEN_BREAKPOINT
} from '../constants';

import repeatBg from 'assets/images/design/backgrounds/repeat.png';
import repeatBgWide from 'assets/images/design/backgrounds/repeat_wide.png';
import repeatBgMobile from 'assets/images/design/backgrounds/repeat_mobile.png';

import bottomBg from 'assets/images/design/backgrounds/bottom.png';
import bottomBgWide from 'assets/images/design/backgrounds/bottom_wide.png';
import bottomBgMobile from 'assets/images/design/backgrounds/bottom_mobile.png';

import scrollDownArrow from 'assets/images/design/icons/arrow-bottom.svg';
import scrollDownArrowMobile from 'assets/images/design/icons/arrow-bottom-mobile.svg';

export const Wrapper = styled.section`
  position: relative;
  white-space: normal;
  color: white;
  padding: 2rem 0;
  overflow: visible;
  background-image: url(${repeatBg});
  background-size: 100% max(calc((100vw / var(--bg-tile-count)) * 2));
  background-position: center bottom;
  background-repeat: repeat-y;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (min-width: ${WIDESCREEN_BREAKPOINT}px) {
    background-image: url(${repeatBgWide});
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    background-image: url(${repeatBgMobile});
  }

  &:after {
    z-index: 1;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    height: var(--curve-transition-height);
    background-image: url(${bottomBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;

    transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing);

    @media screen and (min-width: ${WIDESCREEN_BREAKPOINT}px) {
      background-image: url(${bottomBgWide});
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      background-image: url(${bottomBgMobile});
      display: none;
    }
  }
`;

export const Container = styled.div`
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 0 24px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 16px;
  }
`;

export const MainTitle = styled(MarkdownPreview)`
  padding: 80px 0;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  h1 {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 72px;
    line-heigth: 86.4px;
    border: none;

    transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing);

    strong {
      font-weight: 500;
      color: #fe306b;
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 32px;
      line-height: 32px;
    }
  }
`;

const scrollDownButtonVisible = css`
  opacity: 1;
  top: 80px;
`;

const scrollDownButtonNoVisible = css`
  opacity: 0;
  pointer-events: none;
  top: 0;
`;

export const ScrollDownButtonImg = styled.div`
  display: block;
  margin: 0 auto;
  width: 55px;
  height: 55px;
  background-image: url(${scrollDownArrow});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    background-image: url(${scrollDownArrowMobile});
    width: 21px;
    height: 21px;
  }
`;

export const ScrollDownButton = styled.button`
  width: var(--scroll-down-button-width);
  height: var(--scroll-down-button-width);
  color: white;
  background: #fe306b;
  border-radius: 50%;
  border: none;
  outline: none;
  position: fixed;
  right: max(
    var(--container-horizontal-padding),
    calc(
      (100% - var(--content-width)) / 2 +
        var(--container-horizontal-padding)
    )
  );
  cursor: pointer;
  text-align: center;
  bottom: 80px;

  transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing),
    opacity 0.5s ease, transform 0.5s ease;

  transform: rotate(
    ${({ lastElement }) => (lastElement ? 180 : 0)}deg
  );

  ${({ contentIsVisible }) =>
    contentIsVisible
      ? scrollDownButtonVisible
      : scrollDownButtonNoVisible}

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    right: var(--container-horizontal-padding);
  }
`;

export const Section = styled.section`
  padding-top: 64px;
  padding-bottom: 96px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  &:only-of-type {
    border-bottom: 0;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 56px;
  }
`;
export const SectionObservableElement = styled.section`
  height: calc(95 * var(--vh));
  position: absolute;
  left: 0;
  top: calc(2.5 * var(--vh));
  width: 100%;
  pointer-events: none;
`;

export const SectionHeader = styled.header`
  width: calc(100% - 224px - var(--scroll-down-button-width));
  padding-bottom: 80px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100% - 16px - var(--scroll-down-button-width));
    padding-bottom: 40px;
  }
`;

export const PreTitle = styled.h2`
  color: white;
  text-transform: uppercase;
  font-family: var(--secondary-font);
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
`;

export const Title = styled.h3`
  color: white;
  font-family: var(--secondary-font);
  font-size: 68px;
  line-height: 68px;
  font-weight: 500;
  margin: 0;
  margin-top: 40px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.h4`
  color: white;
  font-family: var(--secondary-font);
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
  margin-top: 96px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const LeadText = styled.p`
  margin-bottom: 0;
  margin-top: 24px;
  color: white;
  font-size: 20px;
  line-height: 30px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: #fe306b;
    text-underline-offset: 4px;
  }
`;

export const FeaturedImage = styled.div`
  width: 100%;
  margin: 40px 0;
  aspect-ratio: ${({ backgroundAspectRatio }) =>
    backgroundAspectRatio};
  background-image: url('${({ backgroundSrc }) => backgroundSrc}');
  background-size: ${({ backgroundSize }) =>
    backgroundSize || 'cover'};
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition || 'center center'};
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => borderRadius || '0'};

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);
`;

export const TagsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  padding: 32px 0;
`;

export const Tag = styled.button`
  background: #3b3b3b;
  padding: 10px 12px;
  border: none;
  outline: none;
  color: white;
  position: relative;
  height: 44px;
  :hover,
  :active,
  :focus {
    color: #3b3b3b;
    background: white;
  }
  font-size: 16px;
  line-height: 24px;

  transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing),
    background 0.2s ease, color 0.2s ease;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 41px;

    font-size: 14px;
    line-height: 21px;
    display: block;
    flex: 1;
    white-space: nowrap;
  }
`;

export const TagTooltip = styled.span`
  white-space: initial;
  border-radius: 4px;
  color: white;
  background: #3b3b3b;
  padding: 10px 12px;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 999;
  transition: left 0.5s ease, opacity 0.5s ease;
  pointer-events: none;
  max-width: 374px;
  opacity: 0;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    color: #3b3b3b;
    background: white;
  }
`;

export const ServiceCardContainer = styled.section`
  display: flex;
  column-gap: 20px;
  width: calc(100% + var(--container-horizontal-padding) * 2 - 2px);
  margin-left: calc(-1 * var(--container-horizontal-padding));

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    width: 100%;
    margin: 0;
    row-gap: 24px;
  }
`;

export const ServiceCard = styled.article`
  border: 1px solid white;
  padding: 24px;
  flex: 1;
`;

export const ServiceCardHeader = styled.header`
  height: 240px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    ${({ background }) => `url(${background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: flex-end;
  padding: 18px 12px;
`;
export const ServiceCardTitle = styled.h1`
  margin: 0;
  font-family: var(--secondary-font);
  font-size: 24px;
  line-height: 28.8px;
  max-width: 189px;
`;

export const ServiceCardBody = styled.main`
  padding-top: 24px;
  font-size: 14px;
  line-height: 25.2px;
`;

export const SubsectionTitle = styled(Subtitle)`
  margin: 0;
  padding: 0;
`;

export const SubsectionBody = styled.main`
  padding-top: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`;

export const Subsection = styled.article``;

export const SubsectionContainer = styled.section`
  display: flex;
  column-gap: 24px;
  padding-top: 56px;
  padding-bottom: 50px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 0;
  }
`;
