import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import {
  Wrapper,
  Container,
  MainTitle,
  Section,
  PreTitle,
  Title,
  SectionHeader,
  LeadText,
  FeaturedImage,
  ScrollDownButton,
  ScrollDownButtonImg,
  Subtitle,
  TagsContainer,
  Tag,
  TagTooltip,
  ServiceCardContainer,
  ServiceCard,
  ServiceCardHeader,
  ServiceCardTitle,
  ServiceCardBody,
  SubsectionContainer,
  Subsection,
  SubsectionTitle,
  SubsectionBody
} from './styled';

import contentDiscovery from 'assets/images/design/content/discovery.webp';
import contentConcept from 'assets/images/design/content/concept.webp';
import build from 'assets/images/design/content/build.gif';

import serviceDesign from 'assets/images/design/content/design_de_servico.png';
import productIdentity from 'assets/images/design/content/identidade_de_produto.png';

import { MOBILE_BREAKPOINT } from '../constants';

const mainTitle = `# Mudanças são inevitáveis, o progresso é uma escolha: **escolha inovar com a gente**`;
const discoveryTags = [
  ['Pesquisa', 'Levantamento de problemas e necessidades.'],
  ['Validação', 'Entrevistas e testes com usuários reais.'],
  ['Ideação', 'Criação de soluções para os problemas levantados.'],
  [
    'Product Canvas',
    'Proposta de valor, objetivos, visão, perfil de usuários.'
  ],
  [
    'Mapas de Jornada',
    'Mapeamento de jornadas de serviço e do usuário.'
  ],
  [
    'Estimativas de Projeto',
    'Estimativas de investimento, tempo e esforço.'
  ],
  [
    'Protótipos',
    'Prototipagem em baixa fidelidade das frentes-chave do produto.'
  ],
  [
    'Propostas de Valor',
    'Estimativas de investimento, tempo e esforço.'
  ],
  [
    'Benchmarks',
    'Estudo de concorrência e análise de melhores práticas.'
  ],
  ['Wireframes', 'Desenho de telas em baixa fidelidade.'],
  ['Userflow', 'Mapeamento do fluxo de telas do produto.'],
  ['Escopo do Produto', 'Mapeamento do fluxo de telas do produto.'],
  [
    'Roadmap do Produto',
    'Plano de release de funcionalidades vinculado aos objetivos do negócio.'
  ],
  [
    'Considerações Técnicas',
    'Mapeamento de escopo do produto e requisitos técnicos.'
  ]
];

const discoverySubSections = [
  /*  {
    title: 'TLOG',
    content:
      'Através da estruturação de um aplicativo, essa discovery propôs facilitar a gestão de fretes da companhia, melhorando sua operação logística, de forma a escalar seu negócio e aumentar sua rentabilidade.'
  },
  {
    title: 'U&M',
    content:
      'Por meio da restruturação de uma plataforma de gestão de equipamentos autônomos, o objetivo desse discovery foi transmitir confiança nos processos executados através do sistema da empresa, tornando-a uma referência em inovação no mercado.'
  },
  {
    title: 'Yucopia',
    content:
      'Mediante a elaboração e conceituação de uma nova funcionalidade para um e-commerce já existente, essa discovery buscou otimizar o processo de fluxo interno de solicitação de compra de diversas empresas.'
  }*/
];

const conceptSubSections = [
  /*  {
    title: 'U&M',
    content:
      'Seja para escalar seu produto, ou usa-lo internamente, nosso time de especialistas se adaptam ao seu contexto e buscam tornar seu produto acessível e eficiente para o usuário.'
  },
  {
    title: 'Lojacorr',
    content:
      'Seja para escalar seu produto, ou usa-lo internamente, nosso time de especialistas se adaptam ao seu contexto e buscam tornar seu produto acessível e eficiente para o usuário.'
  },
  {
    title: 'Marisol',
    content:
      'Seja para escalar seu produto, ou usa-lo internamente, nosso time de especialistas se adaptam ao seu contexto e buscam tornar seu produto acessível e eficiente para o usuário.'
  }*/
];

const conceptTags = [
  [
    'Mockups de alta fidelidade',
    'Desenho de telas em alta fidelidade.'
  ],
  [
    'Protótipos navegáveis ',
    'Definição de interações, fluxo e comportamento.'
  ],
  [
    'Adaptação web/mobile',
    'Criação de soluções para os problemas levantados.'
  ],
  [
    'Design system',
    'Biblioteca de componentes com padrões de comportamento.'
  ],
  [
    'Benchmarks',
    'Estudo de concorrência e análise de melhores práticas.'
  ],
  [
    'Relatórios de Usabilidade',
    'Análise e considerações acerca do teste de usabilidade.'
  ],
  ['Criação de fluxos', 'Mapeamento de telas'],
  ['Userflows', 'Mapeamento do fluxo de telas do produto.'],
  [
    'Métricas de uso',
    'Análise e considerações acerca das métricas do produto.'
  ],
  [
    'Avaliações e feedbacks',
    'Análise e considerações acerca da consistência visual.'
  ]
];

const builTags = [
  ['Prototipação', 'Desenho de telas em alta fidelidade.'],
  ['Validações', 'Definição de interações, fluxo e comportamento.'],
  ['Revisões', 'Criação de soluções para os problemas levantados.'],
  [
    'Suporte',
    'Biblioteca de componentes com padrões de comportamento.'
  ]
];

export const Content = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSectionIndex, setCurrentSection] = useState(0);

  const tooltipRef = useRef(null);
  const btnRef = useRef(null);
  const sectionContainerRef = useRef(null);
  const discoveryRef = useRef(null);
  const conceptRef = useRef(null);
  const buildRef = useRef(null);

  const sections = useMemo(() => {
    return [discoveryRef, conceptRef, buildRef];
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((thisEntry) => {
          if (thisEntry.isIntersecting === true) {
            const sectionAttribute = thisEntry.target.getAttribute(
              'data-section'
            );
            if (sectionAttribute) {
              const currentSectionIndex = Number.parseInt(
                sectionAttribute
              );
              setCurrentSection(currentSectionIndex);
            }
            setIsVisible(true);
          }
        });
      },
      { threshold: [1] }
    );

    sections.forEach((section) => {
      observer.observe(section.current);
    });

    const mainObserver = new IntersectionObserver(
      function (entries) {
        entries.forEach((thisEntry) => {
          if (thisEntry.isIntersecting !== true) {
            setIsVisible(false);
          }
        });
      },
      { threshold: [0, 0.15, 0.5, 1] }
    );

    mainObserver.observe(sectionContainerRef.current);

    return () => {
      observer.disconnect();
      mainObserver.disconnect();
    };
  }, [sections]);

  const scrollBottomOnClick = useCallback(() => {
    const targetIndex =
      currentSectionIndex + 1 <= sections.length - 1
        ? currentSectionIndex + 1
        : 0;
    const element = sections[targetIndex].current;

    const y =
      element.parentElement.getBoundingClientRect().top +
      window.scrollY;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }, [currentSectionIndex, sections]);

  const handleTagMouseEnter = useCallback((e) => {
    if (!tooltipRef.current) {
      return;
    }

    const target =
      e.nativeEvent.type === 'focus'
        ? e.nativeEvent.target
        : e.nativeEvent.relatedTarget;

    if (!target) {
      return;
    }

    const targetParent = target.parentElement;

    if (!targetParent) {
      return;
    }

    const { top, left, bottom } = target.getBoundingClientRect();
    const descriptionAttribute = target.getAttribute(
      'data-description'
    );
    tooltipRef.current.innerText = descriptionAttribute;
    tooltipRef.current.style.maxWidth = '';

    const tooltipComputedStyle = window.getComputedStyle(
      tooltipRef.current
    );
    let tooltipBCR = tooltipRef.current.getBoundingClientRect();

    const availableSpaceToTheRight = document.body.clientWidth - left;

    const maxWidth = Math.min(
      parseInt(tooltipComputedStyle['max-width']),
      availableSpaceToTheRight - 8
    );

    tooltipRef.current.style.width = `${maxWidth}px`;

    const parentBCR = targetParent.getBoundingClientRect();
    const parentComputedStyle = window.getComputedStyle(targetParent);
    const targetRelativeTop =
      top -
      parentBCR.top -
      parseInt(parentComputedStyle['padding-top']);

    const parentHeightWithoutPadding =
      parentBCR.height -
      parseInt(parentComputedStyle['padding-top']) -
      parseInt(parentComputedStyle['padding-bottom']);

    const showTooltipOnBottom =
      targetRelativeTop >
      parentHeightWithoutPadding / 2 -
        parseInt(parentComputedStyle['row-gap']);

    tooltipRef.current.style.left = `${left}px`;
    tooltipRef.current.style.opacity = '1';
    tooltipRef.current.style.borderBottomLeftRadius = '';
    tooltipRef.current.style.borderTopLeftRadius = '';

    tooltipBCR = tooltipRef.current.getBoundingClientRect();

    const isMobile = window.innerWidth <= MOBILE_BREAKPOINT;

    if (isMobile) {
      if (showTooltipOnBottom) {
        tooltipRef.current.style.borderTopLeftRadius = '0';
        tooltipRef.current.style.top = `${
          bottom + window.scrollY + 4
        }px`;
      } else {
        tooltipRef.current.style.borderBottomLeftRadius = '0';
        tooltipRef.current.style.top = `${
          top + window.scrollY - tooltipBCR.height - 4
        }px`;
      }
    } else {
      if (showTooltipOnBottom) {
        tooltipRef.current.style.borderTopLeftRadius = '0';
        tooltipRef.current.style.top = `${
          parentBCR.bottom -
          parseInt(parentComputedStyle['padding-bottom']) +
          window.scrollY +
          8
        }px`;
      } else {
        tooltipRef.current.style.borderBottomLeftRadius = '0';
        tooltipRef.current.style.top = `${
          parentBCR.top +
          parseInt(parentComputedStyle['padding-top']) +
          window.scrollY -
          tooltipBCR.height -
          8
        }px`;
      }
    }
  }, []);

  const handleTagMouseLeave = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.style.opacity = '0';
    }
  }, []);

  return (
    <>
      <TagTooltip ref={tooltipRef} />
      <Wrapper>
        <Container>
          <header>
            <MainTitle source={mainTitle} />
          </header>
          <main ref={sectionContainerRef}>
            <Section>
              <SectionHeader ref={discoveryRef} data-section="0">
                <PreTitle>Product Discovery</PreTitle>
                <Title>Entender para poder criar</Title>
                <LeadText>
                  Da criação de um novo produto a implementação de
                  novas funcionalidades ou a reformulação de um
                  produto já estabelecido, este é o primeiro passo
                  para reduzir os riscos garantir a assertividade do
                  seu produto.{' '}
                  <a
                    href="https://ateliware.com/blog/discovery-em-produtos-digitais"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saiba mais
                  </a>
                </LeadText>
              </SectionHeader>
              <FeaturedImage
                backgroundSrc={contentDiscovery}
                backgroundAspectRatio="1165/480"
                backgroundPosition="50% 20%"
                backgroundSize="102%"
              />
              <Subtitle>O que fazemos:</Subtitle>
              <TagsContainer>
                {discoveryTags.map(([title, description]) => {
                  return (
                    <Tag
                      onMouseEnter={handleTagMouseEnter}
                      onFocus={handleTagMouseEnter}
                      onMouseLeave={handleTagMouseLeave}
                      data-description={description}
                    >
                      {title}
                    </Tag>
                  );
                })}
              </TagsContainer>
              {discoverySubSections.length > 0 && (
                <SubsectionContainer>
                  {discoverySubSections.map(({ title, content }) => (
                    <Subsection>
                      <SubsectionTitle>{title}</SubsectionTitle>
                      <SubsectionBody>{content}</SubsectionBody>
                    </Subsection>
                  ))}
                </SubsectionContainer>
              )}
            </Section>
            <Section>
              <SectionHeader ref={conceptRef} data-section="1">
                <PreTitle>Concept</PreTitle>
                <Title>Criar experiências que inovam</Title>
                <LeadText>
                  Seja para destacar seu produto no mercado, converter
                  e reter usuários, ou resenhar seu produto, aplicamos
                  diversas técnicas de design para que o seu produto
                  seja acessível ao público-alvo e responda com
                  eficiência a cada interação feita pelo usuário.{' '}
                  <a
                    href="https://ateliware.com/blog/product-concept"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saiba mais
                  </a>
                </LeadText>
              </SectionHeader>
              <FeaturedImage
                backgroundSrc={contentConcept}
                backgroundAspectRatio="1165/404"
                backgroundPosition="50% 45%"
                backgroundSize="102%"
              />
              <Subtitle>O que fazemos:</Subtitle>
              <TagsContainer>
                {conceptTags.map(([title, description]) => {
                  return (
                    <Tag
                      onMouseEnter={handleTagMouseEnter}
                      onFocus={handleTagMouseEnter}
                      onMouseLeave={handleTagMouseLeave}
                      data-description={description}
                    >
                      {title}
                    </Tag>
                  );
                })}
              </TagsContainer>
              {conceptSubSections.length > 0 && (
                <SubsectionContainer>
                  {conceptSubSections.map(({ title, content }) => (
                    <Subsection>
                      <SubsectionTitle>{title}</SubsectionTitle>
                      <SubsectionBody>{content}</SubsectionBody>
                    </Subsection>
                  ))}
                </SubsectionContainer>
              )}
            </Section>
            <Section>
              <SectionHeader ref={buildRef} data-section="2">
                <PreTitle>Build</PreTitle>
                <Title>Inovar com boa tecnologia</Title>
                <LeadText>
                  Com uma abordagem baseada em resultados e não apenas
                  em entregas, nosso processo de implementação
                  consiso, aliado a eficiência técnica e a qualidade
                  do design, aumenta consideravelmente a probabilidade
                  de sucesso do produto no seu lançamento.{' '}
                  <a
                    href="https://ateliware.com/blog/design-importante"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Saiba mais
                  </a>
                </LeadText>
              </SectionHeader>
              <FeaturedImage
                backgroundSrc={build}
                backgroundAspectRatio="1165/689"
                backgroundPosition="center center"
                backgroundSize="100%"
                borderRadius="8px"
              />
              <Subtitle>O que fazemos:</Subtitle>
              <TagsContainer>
                {builTags.map(([title, description]) => {
                  return (
                    <Tag
                      onMouseEnter={handleTagMouseEnter}
                      onFocus={handleTagMouseEnter}
                      onMouseLeave={handleTagMouseLeave}
                      data-description={description}
                    >
                      {title}
                    </Tag>
                  );
                })}
              </TagsContainer>
            </Section>
          </main>
          <Section>
            <SectionHeader>
              <PreTitle>Build</PreTitle>
              <Title>Serviços extras</Title>
            </SectionHeader>
            <ServiceCardContainer>
              <ServiceCard>
                <ServiceCardHeader background={productIdentity}>
                  <ServiceCardTitle>
                    Identidade de produto
                  </ServiceCardTitle>
                </ServiceCardHeader>
                <ServiceCardBody>
                  Toda organização tem um nível de comunicação visual
                  que varia de acordo com sua estratégia e produtos
                  digitais, como programas de software, também
                  precisam de uma estratégia visual. O objetivo da
                  identidade de produto é criar uma identidade para um
                  produto, que esteja de acordo com a marca do
                  cliente, mas que possa se comunicar por conta
                  própria. Para criar identidades eficazes para
                  produtos digitais, levamos em conta alguns processos
                  que nos ajudam a entregar com rapidez e dentro das
                  expectativas, uma identidade visual de produto que
                  esteja alinhada com a estratégia da sua marca.
                </ServiceCardBody>
              </ServiceCard>
              <ServiceCard>
                <ServiceCardHeader background={serviceDesign}>
                  <ServiceCardTitle>
                    Design do Serviço
                  </ServiceCardTitle>
                </ServiceCardHeader>
                <ServiceCardBody>
                  Saber se o seu negócio está funcionando bem requer
                  um olhar para dentro da organização como um todo e
                  para o sistema que o cerca: pessoas, produtos,
                  parceiros, processos, interações e clientes. Por
                  meio da combinação de inúmeras habilidades de
                  design, gestão e engenharia de processos o Design de
                  Serviço atua como uma poderosa ferramenta para criar
                  novos serviços ou a melhorar os já existentes, de
                  modo a torná-los mais utilizáveis e desejáveis para
                  os clientes, assim como eficientes e eficazes para
                  as organizações.
                </ServiceCardBody>
              </ServiceCard>
            </ServiceCardContainer>
          </Section>
        </Container>
        <ScrollDownButton
          contentIsVisible={isVisible}
          ref={btnRef}
          onClick={scrollBottomOnClick}
          lastElement={currentSectionIndex === sections.length - 1}
          firstElement={currentSectionIndex === 0}
        >
          <ScrollDownButtonImg />
        </ScrollDownButton>
      </Wrapper>
    </>
  );
};
