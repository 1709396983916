import React from 'react';
import { Container, Title, Wrapper, FormWrapper } from './styled';
import FormContact from 'components/FormContact';

export const ContactUs = ({ page, pageContext }) => {
  const { contactForm, locale } = page || {};
  const { baseURL } = pageContext || {};

  return (
    <Wrapper>
      <Container>
        <Title>Pronto para conversar?</Title>
        <FormWrapper>
          <FormContact
            contactForm={contactForm || {}}
            baseURL={baseURL}
            lang={locale}
            isLp
            rounded
            withSubmitButtonBlock
            withSubmitButtonIcon={false}
            conversionIdentifier="design-product-design"
          />
        </FormWrapper>
      </Container>
    </Wrapper>
  );
};
