const COVER_BACKGROUND = 'white';
const SLIDER_BACKGROUND = 'red';
const SLIDER_DIRECTION = {
  LEFT: 0,
  RIGHT: 1
};
const MOBILE_BREAKPOINT = 520;
const NOTEBOOK_BREAKPOINT = 960;
const WIDESCREEN_BREAKPOINT = 2000;

export {
  COVER_BACKGROUND,
  SLIDER_BACKGROUND,
  SLIDER_DIRECTION,
  MOBILE_BREAKPOINT,
  NOTEBOOK_BREAKPOINT,
  WIDESCREEN_BREAKPOINT
};
