import MarkdownPreview from '@uiw/react-markdown-preview';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../constants';

import { CtaButton as HeaderCtaButton } from '../Header/styled';

import bottomBg from 'assets/images/design/backgrounds/cover_bottom.png';
import bottomBgMobile from 'assets/images/design/backgrounds/cover_bottom_mobile.png';

export const Wrapper = styled.section`
  min-height: calc(
    calc(100 * var(--vh)) - var(--curve-transition-height)
  );
  margin-bottom: var(--curve-transition-height);
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  line-height: 46px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
    min-height: calc(
      calc(100 * var(--vh)) - var(--curve-transition-height)
    );
    display: flex;
    align-items: center;
  }

  &:after {
    z-index: 1;
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    height: var(--curve-transition-height);
    background-image: url(${bottomBg});

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;

    transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing);

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      background-image: url(${bottomBgMobile});
    }
  }
`;

export const CtaButtonWrapper = styled.section`
  display: none;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px 0;
    display: flex;
    align-self: center;
    flex: 1;
    align-items: center;
  }
`;

export const CtaButton = styled(HeaderCtaButton)`
  display: inline-block;
  width: auto;
`;

export const Container = styled.div`
  position: relative;
  max-width: var(--content-width);
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 var(--container-horizontal-padding);
  padding-top: var(--header-height);
  flex: 1;
  justify-content: center;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);
`;

export const StyledMarkdownPreview = styled(MarkdownPreview)`
  width: 100%;
  white-space: initial;
  display: flex;
  align-items: flex-end;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  h1 {
    font-family: var(--secondary-font);
    font-size: 72px;
    line-height: 72px;
    text-align: center;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 500;
    width: 100%;
    color: #191919;
    border: none;

    transition: all var(--transition-duration) var(--transition-delay)
      var(--transition-easing);
    strong {
      color: #f13c65;
      font-weight: 500;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex: 1;
    h1 {
      font-size: 36px;
      line-height: 36px;
    }
  }
`;

export const LeadText = styled.p`
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: #808080;
  margin-top: 80px;
  margin-bottom: 0;
  font-weight: 500;
  white-space: initial;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex: 1;
    font-size: 14px;
    line-height: 21px;
    margin-top: 32px;
  }
`;

export const ScrollDownIndicator = styled.img`
  @keyframes vertical-shake {
    0% {
      transform: translateY(calc(-17px / 2));
    }
    100% {
      transform: translateY(calc(17px / 2));
    }
  }

  z-index: 2;
  width: 17px;
  height: auto;
  position: absolute;
  bottom: calc((var(--curve-transition-height) / 2 - 17px) * -1);
  left: calc(50% - (17px / 2));
  animation: vertical-shake 1s ease-in-out infinite alternate;

  transition: bottom var(--transition-duration)
    var(--transition-delay) var(--transition-easing);
`;
