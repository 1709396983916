import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../constants';

export const Wrapper = styled.header`
  height: var(--header-height);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  max-width: var(--content-width);
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--container-horizontal-padding);

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);
`;

export const Logo = styled.img`
  width: 177px;
`;

export const CtaButton = styled.a`
  font-family: var(--secondary-font);
  cursor: pointer;
  background: #000;
  color: white;
  height: 46px;
  padding: 0 24px;
  display: block;
  border: none;
  outline: none;
  text-decoration: none;
  line-height: 46px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;
