import React from 'react';
import { Container, Text, Wrapper } from './styled';

export const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Text>
          <strong>ateliware.</strong> todos os direitos reservados.
        </Text>
      </Container>
    </Wrapper>
  );
};
