import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Wrapper,
  Container,
  PrevButton,
  NextButton,
  PreTitle,
  Title,
  SlideContentWrapper,
  StyledMarkdownPreview,
  SlideBackground,
  SliderIndexIndicator,
  SliderIndexIndicatorItem,
  SliderIndexIndicatorWrapper,
  SliderBackground
} from './styled';

import iconChevronLeft from 'assets/images/design/icons/chevron-left.svg';
import iconChevronRight from 'assets/images/design/icons/chevron-right.svg';
import watchsonBackgroundImg from 'assets/images/design/slider/watchson.webp';
import sherclockBackgroundImg from 'assets/images/design/slider/sherclock.webp';
import { SLIDER_DIRECTION } from '../constants';

const slides = [
  {
    backgroundColor: '#F13C65',
    backgroundImage: watchsonBackgroundImg,
    pretitle: 'watchson',
    title: 'Conectando habilidades  dentro do escritório.',
    content: `Uma plataforma simples e intuitiva, pensada de forma a proporcionar de maneira rápida e eficiente uma visão das competências técnicas e soft skills dos colaboradores.

Projetada para que a escolha de novos times, ou a realocação em novos frentes de trabalho, seja feita baseada em dados e competências reais de forma eficiente.`
  },
  {
    backgroundColor: '#9A7EFB',
    backgroundImage: sherclockBackgroundImg,
    pretitle: 'sherclock',
    title: 'Um olhar preciso sobre o investimento de tempo.',
    content: `Uma ferramenta que proporciona  o controle de apontamentos de horas para projetos e operações de maneira intuitiva e precisa.

Por meio de uma interface clara e objetiva, é possível inserir, projetar e analisar e  dados valiosos para o entendimento de custo da operação.`
  }
];

export const Slider = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [wrapperBackground, setWrapperBackground] = useState(
    slides[currentSlideIndex].backgroundColor
  );
  const [direction, setDirection] = useState(SLIDER_DIRECTION.LEFT);

  const nextSlide = useCallback(() => {
    setDirection(SLIDER_DIRECTION.RIGHT);
    setCurrentSlideIndex((currentIndex) => {
      let targetIndex = currentIndex + 1;
      if (targetIndex > slides.length - 1) {
        targetIndex = 0;
      }
      return targetIndex;
    });
  }, []);

  const prevSlide = useCallback(() => {
    setDirection(SLIDER_DIRECTION.LEFT);
    setCurrentSlideIndex((currentIndex) => {
      let targetIndex = currentIndex - 1;
      if (targetIndex < 0) {
        targetIndex = slides.length - 1;
      }
      return targetIndex;
    });
  }, []);

  const wrapperRef = useRef(null);

  const [buttonsEnabled, setButtonsEnabled] = useState(false);

  useEffect(() => {
    const animationDuration = window
      .getComputedStyle(wrapperRef.current)
      .getPropertyValue('--slider-animation-duration');

    const animationDurationInMs =
      Number.parseFloat(animationDuration) * 1000;

    setButtonsEnabled(false);
    setTimeout(() => {
      setButtonsEnabled(true);
    }, animationDurationInMs);
  }, [currentSlideIndex]);

  const currentSlide = useMemo(() => {
    return slides[currentSlideIndex];
  }, [currentSlideIndex]);

  const currentSlideBackground = useMemo(() => {
    return currentSlide.backgroundColor;
  }, [currentSlide]);

  useEffect(() => {
    setWrapperBackground(currentSlideBackground);
  }, [setWrapperBackground, currentSlideBackground]);

  return (
    <>
      <SliderBackground background={wrapperBackground} />
      <Wrapper direction={direction} ref={wrapperRef}>
        {slides.map(({ backgroundImage }, i) => {
          return (
            <SlideBackground
              active={currentSlideIndex === i}
              src={backgroundImage}
            />
          );
        })}
        <PrevButton onClick={prevSlide} disabled={!buttonsEnabled}>
          <img
            src={iconChevronLeft}
            alt="seta apontando à esquerda"
          />
        </PrevButton>
        <Container>
          <SlideContentWrapper>
            <PreTitle>{currentSlide.pretitle}</PreTitle>
            <Title>{currentSlide.title}</Title>
            <StyledMarkdownPreview source={currentSlide.content} />
          </SlideContentWrapper>
        </Container>
        <NextButton onClick={nextSlide} disabled={!buttonsEnabled}>
          <img
            src={iconChevronRight}
            alt="seta apontando à direita"
          />
        </NextButton>
        <SliderIndexIndicatorWrapper>
          <SliderIndexIndicator>
            {slides.map((_, i) => {
              return (
                <SliderIndexIndicatorItem
                  active={currentSlideIndex === i}
                />
              );
            })}
          </SliderIndexIndicator>
        </SliderIndexIndicatorWrapper>
      </Wrapper>
    </>
  );
};
