import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ContactUs } from './ContactUs';
import { Content } from './Content';
import { Cover } from './Cover';
import { Footer } from './Footer';
import { Header } from './Header';
import { PreFooter } from './PreFooter';
import { Slider } from './Slider';
import { Wrapper } from './styled';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';

const DesignPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { ateliware } = data;
  const { page } = ateliware;

  const { seo } = page;
  const { locale } = pageContext;

  useEffect(() => {
    const handleOnResize = () => {
      document
        .querySelector(':root')
        .style.setProperty('--vh', window.innerHeight / 100 + 'px');
    };
    window.addEventListener('resize', handleOnResize);

    handleOnResize();

    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <style>
          {`
          html,body{
            overflow-x:hidden;
          }
          `}
        </style>
      </Helmet>
      <SEO
        meta_title={
          locale === 'pt'
            ? seo?.metaTitle
            : 'software and digital product development'
        }
        meta_description={
          locale === 'pt'
            ? seo?.metaDescription
            : 'More than a supplier that understands technology, here at ateliware we transform and simplify business. We are a software studio that develops handmade products and solutions.'
        }
        og_title={
          locale === 'pt'
            ? seo?.ogTitle
            : 'software and digital product development'
        }
        og_description={
          locale === 'pt'
            ? seo?.ogDescription
            : 'More than a supplier that understands technology, here at ateliware we transform and simplify business. We are a software studio that develops handmade products and solutions.'
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Wrapper>
        <Header />
        <Cover />
        <Slider />
        <Content />
        <PreFooter />
        <ContactUs page={page} pageContext={pageContext} />
        <Footer />
      </Wrapper>
    </>
  );
};

export const designPageQuery = graphql`
  query DesignPageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
        locale
        slug
      }
    }
  }
`;

export default DesignPage;
