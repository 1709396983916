import MarkdownPreview from '@uiw/react-markdown-preview';
import styled, { css } from 'styled-components';
import {
  MOBILE_BREAKPOINT,
  NOTEBOOK_BREAKPOINT,
  SLIDER_DIRECTION
} from '../constants';

export const SliderBackground = styled.section`
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: calc(200 * var(--vh));
  z-index: -1;
  background: ${({ background }) => background};

  transition: all var(--slider-animation-duration)
    var(--transition-delay) var(--transition-easing);
`;

export const Wrapper = styled.section`
  height: calc(100 * var(--vh));
  position: relative;
  background: ${({ background }) => background};
  display: flex;
  justify-content: space-evenly;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  --slider-button-horizontal-margin: 64px;
  --slider-button-horizontal-margin-small: 16px;
  --slider-background-padding-bottom: 68px;
  --slider-background-padding-top: 85px;

  ${({ direction }) =>
    direction === SLIDER_DIRECTION.LEFT
      ? `
    --indicator-active-start-pos:100%;
    --indicator-active-end-pos:0;
    --indicator-inactive-start-pos:0;
    --indicator-inactive-end-pos:-100%;

    --enter-start-pos:150%;
    --enter-end-pos:calc(50% + 47px);
    --exit-start-pos:calc(50% + 47px);
    --exit-end-pos:-50%; `
      : `
    --indicator-active-start-pos:-100%;
    --indicator-active-end-pos:0;
    --indicator-inactive-start-pos:0;
    --indicator-inactive-end-pos:100%;

    --enter-start-pos:-50%;
    --enter-end-pos:calc(50% + 47px);
    --exit-start-pos:calc(50% + 47px);
    --exit-end-pos:150%;`}

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: var(--slider-height);

    --slider-padding-top: 64px;
    --slider-background-padding-top: 32px;

    ${({ direction }) =>
      direction === SLIDER_DIRECTION.LEFT
        ? `
    --enter-start-pos:100%;
    --enter-end-pos:0%;
    --exit-start-pos:0;
    --exit-end-pos:-100%; `
        : `
    --enter-start-pos:-100%;
    --enter-end-pos:0%;
    --exit-start-pos:0%;
    --exit-end-pos:100%;`}
  }
`;

export const Container = styled.div`
  max-width: calc(var(--content-width) + 80px);
  overflow: hidden;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
  }
`;

export const Button = styled.button`
  background: rgba(53, 53, 53, 0.4);
  border-radius: 50%;
  height: auto;
  align-self: center;
  cursor: pointer;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: none;
  outline: none;
  aspect-ratio: 1;
  transition: all 0.5s ease;

  img {
    max-width: 100%;
    height: auto;
    transition: all 0.5s ease;
  }

  &:hover {
    background: rgba(53, 53, 53, 0.6);
  }

  &:active {
    background: rgba(53, 53, 53, 0.8);
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  @media screen and (max-width: ${NOTEBOOK_BREAKPOINT}px) {
    width: 40px;
    height: 40px;
    img {
      max-height: 12px;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    top: 25%;
  }
`;

export const PrevButton = styled(Button)`
  margin-left: var(--slider-button-horizontal-margin);
  margin-right: var(--slider-button-horizontal-margin-small);

  img {
    margin-left: -7%;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
    left: var(--slider-button-horizontal-margin-small);
  }
`;
export const NextButton = styled(Button)`
  margin-right: var(--slider-button-horizontal-margin);
  margin-left: var(--slider-button-horizontal-margin-small);

  img {
    margin-left: 7%;
  }

  @media screen and (max-width: ${NOTEBOOK_BREAKPOINT}px) {
    margin-right: var(--slider-button-horizontal-margin-small);
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
    right: var(--slider-button-horizontal-margin-small);
  }
`;

export const PreTitle = styled.span`
  color: white;
  text-transform: uppercase;
  font-family: var(--secondary-font);
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 80px;
  display: block;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
  }
`;

export const Title = styled.h1`
  color: white;
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 51px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const SlideContentWrapper = styled.main`
  max-width: 50%;
  white-space: initial;
  padding-top: 120px;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${NOTEBOOK_BREAKPOINT}px) {
    max-width: 60%;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 16px;
    max-width: 100%;
    overflow: hidden;
    overflow-y: auto;
    top: 40vh;
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100 * var(--vh));
  }
`;

export const StyledMarkdownPreview = styled(MarkdownPreview)`
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  margin: 0;
  columns: 2;
  column-gap: 36px;
  font-family: var(--primary-font);

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  p {
    margin: 0;
  }

  @media screen and (max-width: ${NOTEBOOK_BREAKPOINT}px) {
    columns: 1;
    p {
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    columns: 2;
    margin-top: 51px;
    font-size: 14px;
    line-height: 19.6px;
    column-gap: 16px;

    p {
      margin-bottom: 0;
    }
  }
`;

const activeSlideBackground = css`
  @keyframes slide-bg-enter {
    0% {
      transform: translateX(var(--enter-start-pos));
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    100% {
      transform: translateX(var(--enter-end-pos));
      opacity: 1;
    }
  }
  animation: slide-bg-enter var(--slider-animation-duration)
    var(--transition-delay) ease-in-out both;
`;

const inactiveSlideBackground = css`
  @keyframes slide-bg-exit {
    0% {
      transform: translateX(var(--exit-start-pos));
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      transform: translateX(var(--exit-end-pos));
      opacity: 0;
    }
  }
  animation: slide-bg-exit var(--slider-animation-duration)
    var(--transition-delay) ease-in-out both;
`;

export const SlideBackground = styled.section`
  position: absolute;
  width: 100%;
  height: calc(
    100 * var(--vh) - var(--slider-background-padding-top) -
      var(--slider-background-padding-bottom)
  );
  right: 0;
  top: var(--slider-background-padding-top);
  z-index: 0;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  ${({ active }) =>
    active ? activeSlideBackground : inactiveSlideBackground};

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    background-position: center center;
    height: calc(
      calc(40 * var(--vh)) -
        (
          var(--slider-padding-top) + var(--slider-indicator-height) +
            var(--slider-background-padding-top)
        )
    );
    width: 100%;
    left: 0;
    top: calc(
      var(--slider-padding-top) + var(--slider-indicator-height) +
        var(--slider-background-padding-top)
    );
  }
`;

export const SliderIndexIndicatorWrapper = styled.footer`
  position: absolute;
  bottom: 130px;
  width: 100%;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${NOTEBOOK_BREAKPOINT}px) {
    bottom: 32px;
    padding: 0 var(--container-horizontal-padding);
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    bottom: auto;
    top: var(--slider-padding-top);
  }
`;

export const SliderIndexIndicator = styled.ul`
  margin: 0 auto;
  padding: 0;
  display: flex;
  column-gap: 19px;
  list-style: none;
  justify-content: center;
`;

const indicatorActiveAfter = css`
  @keyframes indicator-enter {
    0% {
      transform: translateX(var(--indicator-active-start-pos));
    }
    100% {
      transform: translateX(var(--indicator-active-end-pos));
    }
  }
  animation: indicator-enter var(--slider-animation-duration)
    var(--transition-delay) ease-in-out both;
`;

const indicatorInactiveAfter = css`
  @keyframes indicator-exit {
    0% {
      transform: translateX(var(--indicator-inactive-start-pos));
    }
    100% {
      transform: translateX(var(--indicator-inactive-end-pos));
    }
  }
  animation: indicator-exit var(--slider-animation-duration)
    var(--transition-delay) ease-in-out both;
`;

export const SliderIndexIndicatorItem = styled.li`
  position: relative;
  background: rgba(25, 25, 25, 0.2);
  width: 100%;
  max-width: 276px;
  height: var(--slider-indicator-height);
  transition: background var(--slider-animation-duration)
    var(--transition-delay) var(--transition-easing);
  overflow: hidden;

  :after {
    content: '';
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;

    ${({ active }) =>
      active ? indicatorActiveAfter : indicatorInactiveAfter}
  }
`;
