import React from 'react';
import {
  Wrapper,
  Container,
  StyledMarkdownPreview,
  LeadText,
  ScrollDownIndicator,
  CtaButton,
  CtaButtonWrapper
} from './styled';
import chevronBottom from 'assets/images/design/icons/chevron-bottom-black.svg';

const title = `# Ajudamos a tornar produtos digitais **radicalmente** relevantes`;
const leadText =
  'Da estratégia aos detalhes finais, torne seu produto relevante para seu negócio através do design. ';

export const Cover = () => {
  return (
    <Wrapper>
      <Container>
        <StyledMarkdownPreview source={title} />
        <LeadText>{leadText}</LeadText>
        <CtaButtonWrapper>
          <CtaButton
            href="https://ateliware.com/contact"
            target="_blank"
            rel="noreferrer"
          >
            Vamos conversar
          </CtaButton>
        </CtaButtonWrapper>
        <ScrollDownIndicator src={chevronBottom} />
      </Container>
    </Wrapper>
  );
};
