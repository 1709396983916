import styled from 'styled-components';

export const Wrapper = styled.footer`
  position: relative;
  background: #191919;
  white-space: normal;
  padding: 2rem 0;
`;

export const Container = styled.div`
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 24px 0;
`;

export const Text = styled.span`
  display: block;
  margin: 0 auto;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 22.4px;
`;
