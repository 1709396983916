import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../constants';

export const Wrapper = styled.section`
  height: calc(
    calc(100 * var(--vh)) + var(--curve-transition-height)
  );
  position: relative;
  background: black;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
    overflow: hidden;
    height: min-content;
    background: #191919;
  }
`;

export const Container = styled.div`
  max-width: var(--content-width);
  margin: 0 auto;
`;

export const Video = styled.video`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;

  transition: all var(--transition-duration) var(--transition-delay)
    var(--transition-easing);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    position: relative;
    min-height: initial;
    width: 100%;
  }
`;
