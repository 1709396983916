import styled from 'styled-components';
import {
  MOBILE_BREAKPOINT,
  WIDESCREEN_BREAKPOINT
} from './constants';

export const Wrapper = styled.main`
  --primary-font: 'Inter', sans-serif;
  --secondary-font: 'Space Grotesk', sans-serif;
  --scroll-down-button-width: 170px;
  --bg-tile-count: 7;
  --bg-curve-transition-proportion: 1.4;
  --content-width: 1167px;
  --header-height: 154px;
  --curve-transition-height: calc(
    (100vw / var(--bg-tile-count)) *
      var(--bg-curve-transition-proportion)
  );
  --transition-delay: 0s;
  --transition-duration: 1s;
  --transition-easing: ease-in;
  --slider-animation-duration: 1s;
  --slider-indicator-height: 4px;
  --container-horizontal-padding: 24px;

  @media screen and (min-width: ${WIDESCREEN_BREAKPOINT}px) {
    --slider-animation-duration: 2s;
    --bg-tile-count: 10;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    --slider-height: max(calc(100 * var(--vh)), 850px);
    --slider-padding-top: 64px;
    --slider-background-padding-top: 32px;
    --slider-animation-duration: 2s;
    --bg-tile-count: 1.46;
    --bg-curve-transition-proportion: 0.53;
    --scroll-down-button-width: 58px;
    --container-horizontal-padding: 16px;
  }

  position: relative;
  white-space: nowrap;
  font-family: var(--primary-font);
  transition: background var(--transition-duration)
    var(--transition-delay) var(--transition-easing);
`;
