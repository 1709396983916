import React from 'react';
import { Container, CtaButton, Logo, Wrapper } from './styled';

import logoAteliware from 'assets/images/logo-ateliware.svg';

export const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Logo src={logoAteliware} alt="Logo Ateliware" />
        <CtaButton
          href="https://ateliware.com/contact"
          target="_blank"
          rel="noreferrer"
        >
          Vamos conversar
        </CtaButton>
      </Container>
    </Wrapper>
  );
};
